const config = require('./config.js');

// Initialize global configuration
const success = config.init();
if (!success) {
  throw new Error('Abort!');
}
const globalConfig = config.get();
try {
  ((gcVersion, gcSite, gcSettings, mathFloor, isArray) => {
    /** @type {LoadScriptHandler} */
    let loadScriptHandler = null;

    const scriptSetup = {
      scripts: [
        {
          name: 'qchoice',
          loadModule: () => require('./qchoice.js'),
          firstParty: true
        },
        {
          name: 'adconsent',
          skipLoadOnExists: true,
          loadModule: () => require('./adconsent.js'),
          onLoad: {
            emitEvent: 'adnginLoaderReady',
            scripts: [
              { name: 'pbjs', obj: { que: [] }, queue: 'que', path: '?v=' + escape(gcVersion) },
              {
                name: 'apstag',
                obj: {
                  init: function () {
                    this._Q.push(['i', arguments]);
                  },
                  fetchBids: function () {
                    this._Q.push(['f', arguments]);
                  },
                  setDisplayBids: () => {},
                  targetingKeys: () => [],
                  _Q: []
                }
              },
              { name: 'gpt', obj: { cmd: [] }, queue: 'cmd' },
              { name: 'adsbygoogle', obj: [] },
              { name: 'adngin', obj: { queue: [], metrics: { timing: {} } }, queue: 'queue', path: '/' + escape(gcSite) + '/' + escape(gcVersion) + '/adngin.js', firstParty: true },
              { name: 'scripts', argus: { obj: { cmd: [] }, queue: 'cmd', firstParty: true } } // handled differently because the globalConfig.settings for this entry is an array
            ]
          },
          firstParty: true
        }
      ]
    };

    // performance.now polyfill
    const perf = window.performance;
    const getPerformanceNow = perf.now ? () => mathFloor(perf.now()) : () => -1;
    const getPerformanceEntry = matches => (perf.getEntriesByType ? perf.getEntriesByType('resource') : []).find(entry => matches(entry.name)) || { startTime: -1, responseEnd: -1 };

    const setPerformanceMetric = (name, matches) => {
      const entry = getPerformanceEntry(matches);
      const timingName = (timing[name] ||= {});
      timingName.requested = [mathFloor(entry.startTime)];
      timingName.loaded = [mathFloor(entry.responseEnd)];
      timingName.ready = [getPerformanceNow()];
    };

    /** @type {LoadScriptHandler} */
    const loadScriptWithConsentWallCheck = (script, name, url, callback) => {
      // if consentWall, load 3rd party script only if the user is not a consent wall subscriber
      const adConsentObjName = globalConfig.settings.adconsent.objName || 'adconsent';

      if (window[adConsentObjName] && !script.firstParty) {
        window[adConsentObjName]('isConsentWallUser', null, r => {
          if (!r.hasSubscription) {
            loadScript(script, name, url, callback);
          }
        });
      } else {
        loadScript(script, name, url, callback);
      }
    };

    /** @type {LoadScriptHandler} */
    const loadScript = (_script, name, url, callback) => {
      const globalConfigResources = (globalConfig.resources ||= {});

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.onload = () => {
        setPerformanceMetric(name, entryName => entryName.toLowerCase().indexOf(url.toLowerCase()) >= 0);

        globalConfigResources[name].loaded = true;
        if (callback) callback();
      };

      timing[name] ||= {};
      timing[name].queued = [getPerformanceNow()];

      globalConfigResources[name] = { scriptElement: scriptElement, loaded: false };

      scriptElement.src = url;

      document.head.appendChild(scriptElement);
    };

    const loadScriptObj = (script, callback) => {
      const queryParameters = gcSettings[script.name].queryParameters;
      let url = gcSettings[script.name].url + (script.path || '') + (queryParameters ? '?' + queryParameters : '');

      // experiment
      const exp = gcSettings[script.name].exp;
      if (exp && exp.url && exp.freq) {
        const param = exp.queryParameters || queryParameters || '';
        if (mathFloor(Math.random() * 100) < exp.freq) {
          url = exp.url + (exp.path || script.path || '') + (param ? '?' + param : '');
        }
      }
      loadScriptHandler(script, script.name, url, callback);
    };

    const handleAllScripts = (scriptsCfg, singleScriptHandler, allScriptsHandler) => {
      scriptsCfg.scripts.forEach(script => {
        const scriptSettings = gcSettings[script.name] || {};
        if (!isArray(scriptSettings) && scriptSettings.load) {
          singleScriptHandler(scriptSettings.objName, script);
        }
        if (script.onLoad && script.onLoad.scripts) {
          allScriptsHandler(script.onLoad);
        }
      });
    };

    const initScriptObject = (objName, script) => {
      const stubProps = script.obj;

      if (stubProps) {
        if (!window[objName]) {
          window[objName] = stubProps;
        } else {
          const stub = window[objName];
          for (const prop in stubProps) {
            stub[prop] = stub[prop] || stubProps[prop];
          }
        }
      }
    };

    const initAllScriptObjects = scriptsCfg => handleAllScripts(scriptsCfg, initScriptObject, initAllScriptObjects);

    const initScriptMetrics = (objName, script) => {
      const scriptQueue = script.queue;
      if (scriptQueue) {
        window[objName][scriptQueue].push(() => {
          const scriptTiming = (timing[script.name || objName] ||= {});
          scriptTiming.apiReady = [getPerformanceNow()];
        });
      }
    };

    const initAllScriptMetrics = scriptsCfg => handleAllScripts(scriptsCfg, initScriptMetrics, initAllScriptMetrics);

    const loadScripts = scriptsCfg => {
      if (!scriptsCfg) return false;
      if (scriptsCfg.emitEvent) {
        window.dispatchEvent(new CustomEvent(scriptsCfg.emitEvent));
        adnginObj[scriptsCfg.emitEvent] = true;
        timing['loader'][scriptsCfg.emitEvent] = [getPerformanceNow()];
      }
      if (scriptsCfg.scripts) {
        scriptsCfg.scripts.forEach(script => {
          const scriptSettings = gcSettings[script.name] || {};

          const needsLoading = () => {
            const scriptObjectExists = !!window[scriptSettings.objName];
            return scriptSettings.load && (!scriptObjectExists || (scriptObjectExists && !script.skipLoadOnExists));
          };

          if (isArray(scriptSettings)) {
            scriptSettings.forEach(scriptEntry => {
              // Load the script if freq is missing or a random number between 0..99 is < freq. freq supports range from 0..100%
              if (scriptEntry.freq === undefined || mathFloor(Math.random() * 100) < scriptEntry.freq) {
                const objName = scriptEntry.name;
                const scriptData = script[objName] || {};
                initScriptObject(objName, scriptData);
                initScriptMetrics(objName, scriptData);
                loadScriptHandler(scriptData, scriptEntry.name || scriptEntry.url, scriptEntry.url);
              }
            });
          } else {
            if (needsLoading() && script.loadModule) {
              script.loadModule();
            }
            if (needsLoading() && scriptSettings.url) {
              loadScriptObj(script, () => loadScripts(script.onLoad));
            } else {
              loadScripts(script.onLoad);
            }
          }
        });
      }
    };

    // Create global window objects and queues
    (window.adsbygoogle ||= []).pauseAdRequests = 1;
    window.snigelPubConf ||= {};
    initAllScriptObjects(scriptSetup);
    const adnginObj = window[gcSettings.adngin.objName];
    const timing = adnginObj.metrics.timing;

    // Initialize metrics objects for all scripts
    setPerformanceMetric('loader', entryName => /.*snigel.*loader.js$/i.test(entryName));
    initAllScriptMetrics(scriptSetup);

    // Check if consent wall is enabled
    if (globalConfig.settings && globalConfig.settings.adconsent && globalConfig.settings.adconsent.consentWall) {
      loadScriptHandler = loadScriptWithConsentWallCheck;
    } else {
      loadScriptHandler = loadScript;
    }

    // Load scripts
    loadScripts(scriptSetup);
  })(globalConfig.version, globalConfig.site, globalConfig.settings, Math.floor, Array.isArray);
} catch (e) {
  if (globalConfig.passThroughException === true) {
    throw e;
  } else {
    console.error(e);
  }
}

/**
 * Represents a script object that can be embedded at different level of the script setup.
 *
 * @typedef {{
 *   name: string, // Name of the script, used as a key to get the settings in globalConfig.settings
 *   obj?: any, // Stub object immediately installed by the loader before the script is loaded
 *   queue?: string, // Command queue of the script, used by the metrics to measure when the script is started
 *   path: string, // path and query parameters that will be added to the url
 *   firstParty?: boolean // if the script a first party or third party script. We consider the snigel scripts being first party
 * }} Script
 */

/**
 * Handler function to load a script. It has 2 implementations: loadScript, loadScriptWithConsentWallCheck.
 *
 * @callback LoadScriptHandler
 * @param {Script} script the script setup
 * @param {string} name
 * @param {string} url
 * @param {Function} callback to be called once the script is loaded
 * @returns {void}
 */
